/* eslint-disable no-param-reassign */
import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate } from '@azure/msal-react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import LoadingPage from '@viking-eng/loading-page';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { lazy, Suspense } from 'react';
import IdleTimer from 'react-idle-timer';
import { Redirect, Route, Switch } from 'react-router-dom';
import { initializeBookingData, reactPlugin, triggerPageView } from './common/Analytics';
import { MSAL_CONFIG } from './common/AuthConfig';
import { msalInstance } from './common/Authentication';
import {
  AIR_PATHS,
  APP_PATHS,
  APP_TITLE,
  AUTH_STATES,
  DAYS_TO_GO,
  ENVIRONMENT_CODE,
  LOCK_TYPES,
  MODALS,
  MVJ_LOCK_TIMER,
  NO_BOOKING_ID,
  NO_IMAGE,
  OOPS_PAGE_ERRORS,
  REDIRECT_APP_PATHS,
  TAB_PATHS,
  USER_TYPES,
  VOYAGE_STATUSES,
} from './common/Constants';
import { liveChatUpdate } from './common/LivePerson';
import { debugLog, getChatContainerElements, getQueryParams, hideAllModals, navigateTo } from './common/Utils';
import { AirNav, Footer, Header } from './components';
import HeaderFooterWrapper from './components/headerFooterWrapper/HeaderFooterWrapper';
import './css/global.scss';
import { CloseToSailingModal, ViewOnlyModal } from './modals';
// eslint-disable-next-line no-restricted-imports
import './modals/Modals.scss';
import OopsPage from './pages/oopsPage/OopsContainer';
import voyageNotAvailable from './pages/voyageNotAvailablePage/VoyageNotAvailContainer';
import PropTypes from './PropTypes';

// Incorrect prop type warning error is fixed in 4.4.1 betas
// https://github.com/ReactTraining/react-router/issues/6420
const Account = lazy(() => import('./pages/account/AccountContainer'));
const BeforeYouGo = lazy(() => import('./pages/beforeYouGo/BeforeYouGoContainer'));
const TravelRequirements = lazy(() => import('./pages/travelRequirements/TravelRequirementsContainer'));
const Calendar = lazy(() => import('./pages/calendar/CalendarContainer'));
const SplashPage = lazy(() => import('./pages/splashPage/SplashPageContainer'));
const Documents = lazy(() => import('./pages/documents/DocumentsContainer'));
const Extensions = lazy(() => import('./pages/extensions/ExtensionsContainer'));
const Help = lazy(() => import('./pages/help/HelpContainer'));
const LoginFaqs = lazy(() => import('./pages/loginFaqs/LoginFaqsContainer'));
const Home = lazy(() => import('./pages/home/HomeContainer'));
const Notifications = lazy(() => import('./pages/notifications/NotificationsContainer'));
const Onboard = lazy(() => import('./pages/onboard/OnboardContainer'));
const Payments = lazy(() => import('./pages/payments/PaymentsContainer'));
const PassengerTicketContract = lazy(() => import('./pages/passengerTicketContract/PassengerTicketContractContainer'));
const Shorex = lazy(() => import('./pages/shorex/ShorexContainer'));
const TravelBooking = lazy(() => import('./pages/travelBooking/TravelBookingContainer'));
const Maintenance = lazy(() => import('./pages/maintenancePage/MaintenancePageContainer'));
const SystemMaintenance = lazy(() => import('./pages/systemMaintenancePage/SystemMaintenancePage'));
const DiningMenu = lazy(() => import('./pages/onboard/diningBeverage/diningMenu/DiningMenuContainer'));
const SchedulePayment = lazy(() => import('./pages/schedulePayment/SchedulePaymentContainer'));
const SubmarineVideo = lazy(() => import('./pages/submarineVideo/SubmarineVideoContainer'));

const NewAir = lazy(() => import('./pages/newAir/AirContainer'));
const AirSearch = lazy(() => import('./pages/newAir/search/AirSearchContainer'));
const Seats = lazy(() => import('./pages/newAir/seats/SeatsContainer'));
const AirConfirm = lazy(() => import('./pages/newAir/confirm/ConfirmContainer'));
const AirReview = lazy(() => import('./pages/newAir/review/ReviewContainer'));
const ConfirmResponse = lazy(() => import('./pages/newAir/confirmResponse/ConfirmResponseContainer'));
const SelectPassengers = lazy(() => import('./pages/newAir/selectPassengers/SelectPassengersContainer'));

const renderComponent = (Component, otherProps) => (props) => <Component {...props} {...otherProps} />;

// Helper functions for within App component
const getIsAuthenticationComplete = ({ prevUserData, authData, isAuthenticating, userData, prevState }) =>
  authData?.uniqueId &&
  (!prevState.mounted ||
    (userData?.email && userData.email !== prevUserData.email) ||
    (!isAuthenticating && prevState?.isAuthenticating));

// ----------------------------------- end helper function -----------------------------------

// TODO:  MR-14777 - Refactor to be a functional component and use hooks.
//        Update routes such that there are routes that require auth and others that do not
//        Header & Footer will render in all cases but have different content based on auth
class App extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = MsalContext;

  static handleSupportReset() {
    localStorage.clear();
  }

  constructor(props) {
    super(props);

    this.state = {
      commonContentLoaded: false,
      isInitialLoadComplete: false,
      authState: AUTH_STATES.UNKNOWN,
      displayViewOnlyModal: false,
      redirectToError: false,
      mounted: false,
      isImpersonationRedirect: false,
      isAuthenticating: false,
    };

    this.idleTimer = null;

    this.navSignOut = this.navSignOut.bind(this);
    this.idleSignOut = this.idleSignOut.bind(this);
  }

  async componentDidMount() {
    if (window.location.pathname === APP_PATHS.SUPPORT_RESET) {
      App.handleSupportReset();
    }

    const {
      location: { search },
      handleQueryParams,
      impersonationData,
    } = this.props;
    const queryParams = getQueryParams(search);
    handleQueryParams(queryParams);

    if (impersonationData && !isEmpty(impersonationData)) {
      this.setState({
        isImpersonationRedirect: true,
      });
    }

    this.setState({
      mounted: true,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { mounted, isAuthenticating: isAuthenticatingNext } = nextState;
    const { isAuthenticating } = this.state;
    if (!mounted) {
      return false;
    }
    if (isAuthenticating && !isAuthenticatingNext) {
      return true;
    }
    const {
      location: { pathname },
    } = nextProps;
    const {
      location: { pathname: oldPath },
    } = this.props;

    if (pathname === APP_PATHS.SIGN_OUT) {
      if (pathname !== oldPath) {
        this.navSignOut();
        return false;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activeRequestCount,
      authData,
      loadedStatus,
      location: { pathname },
      bookingData,
      changeBooking,
      lockStatus,
      alertUserCloseToDeparture,
      splashPageLink,
      isViewOnly,
      handleModalOpen,
      clearGIFNextLocation,
      clearPaymentNextLocation,
      updatePreviousPage,
      pageName,
      userType,
      userBookingData,
      userData,
      handleAzureAuth,
      impersonationData,
    } = this.props;
    const { isAuthenticating, authState, isInitialLoadComplete, displayViewOnlyModal } = this.state;
    const addPaymentMethodRegex = /(ach)\/?$/i;
    const editPaymentMethodRegex = /(ach)\/\d+/i;

    // Trigger auth start Azure
    if (authData.uniqueId && authData.uniqueId !== prevProps?.authData.uniqueId) {
      handleAzureAuth(authData).then(() => {
        if (window.location.pathname.startsWith(APP_PATHS.LOGIN)) {
          navigateTo(APP_PATHS.INDEX);
        }
      });
    }
    if (
      (userData?.email && userData?.email !== prevProps?.userData?.email) ||
      !prevState.mounted ||
      (!prevProps.impersonationData && impersonationData)
    ) {
      this.setState({
        isAuthenticating: true,
      });
      this.authenticateB2c();
    }
    if (!isAuthenticating && prevState?.isAuthenticating) {
      this.setAuthenticationState(prevProps, prevState);
    }

    if (this.isLocationChanged(prevProps)) {
      const isSignedOut = authState === AUTH_STATES.SIGNED_OUT;
      let page = pathname;
      if (page === '/myjourney') {
        page = isSignedOut ? APP_PATHS.HOME : APP_PATHS.LOGIN;
      }
      initializeBookingData({ event: 'spa_page_view', event_name: 'spa_page_view', ...bookingData }, page);
    }
    window.document.title = pageName ? `${APP_TITLE} - ${pageName}` : APP_TITLE;

    if (this.isLocationChanged(prevProps)) {
      this.passDataToLPChatAgent();

      const preventScroll = [
        prevProps.location.pathname.match(addPaymentMethodRegex) && pathname.match(editPaymentMethodRegex),
      ];
      if (preventScroll.every((condition) => !condition)) {
        window.scrollTo(0, 0);
      }
      const guestInfoTabPath = `${APP_PATHS.DOCUMENTS}/${TAB_PATHS.documents.guestInformation}`;
      if (prevProps.location.pathname.toLowerCase() === guestInfoTabPath) {
        // Clear GIF's next location so user isn't redirected when completing GIF next time around
        clearGIFNextLocation();
      }
      if (prevProps.location.pathname.toLowerCase() === APP_PATHS.ONBOARD_CREDIT_CARD) {
        clearPaymentNextLocation();
      }
    }
    if (
      !isInitialLoadComplete &&
      loadedStatus &&
      !activeRequestCount &&
      !changeBooking &&
      (lockStatus || (userData?.email && userBookingData.length === 0))
    ) {
      this.setState({
        isInitialLoadComplete: true,
      });
    }
    if (changeBooking && isInitialLoadComplete) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isInitialLoadComplete: false,
      });
    }

    const noAlertPages = [APP_PATHS.OOPS_PAGE, APP_PATHS.MAINTENANCE, APP_PATHS.SYSTEM_MAINTENANCE];
    if (!prevProps.isViewOnly && isViewOnly) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        displayViewOnlyModal: true,
      });
    }

    if (isInitialLoadComplete && displayViewOnlyModal && !noAlertPages.includes(pathname)) {
      handleModalOpen(MODALS.VIEW_ONLY);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        displayViewOnlyModal: false,
      });
    }
    if (isInitialLoadComplete && !splashPageLink && !noAlertPages.includes(pathname)) {
      if (alertUserCloseToDeparture && !isViewOnly && userType === USER_TYPES.CONSUMER) {
        this.alertIfCloseToSailing();
      }
    }

    const lpChatClass = getChatContainerElements() || [];

    const displayStyle = userType === USER_TYPES.TA ? 'none' : 'block';
    lpChatClass.forEach((x) => {
      // eslint-disable-next-line no-param-reassign
      x.style.display = displayStyle;
    });

    if (prevProps.location.pathname !== pathname) {
      updatePreviousPage(prevProps.location.pathname);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    // This is needed here rather than componentDidUpdate because the modal still needs to be in
    // the DOM so it can be hidden through JavaScript
    if (this.isLocationChanged(prevProps)) {
      hideAllModals();
    }
    return null;
  }

  setAuthenticationState({ userData: prevUserData = {} }, prevState) {
    const {
      authData,
      autoAcceptPtc,
      fetchBookings,
      fetchCart,
      fetchCommonContent,
      fetchNotifications,
      handleDeepLink,
      handleValidateAndUpdateLoggedInUser,
      location: { pathname },
      refreshIdTokenExpiration,
      refreshLockStatus,
      userBookingData,
      userData,
      userType,
      verifyLockUnlockStatus,
      setAnalyticsSessionId,
      ptc,
    } = this.props;
    const { isAuthenticating } = this.state;
    const isAuthenticatingComplete = getIsAuthenticationComplete({
      prevUserData,
      authData,
      isAuthenticating,
      userData,
      prevState,
    });
    if (isAuthenticatingComplete) {
      const bookings = userBookingData;
      if (bookings?.length > 0) {
        handleValidateAndUpdateLoggedInUser(authData, bookings).then(
          async ({ currentBookingId, validatedBookings }) => {
            let bookingId = null;
            let passengerNumber = null;
            if (validatedBookings) {
              // get most recent bookingId and passengerNumber
              const currentBooking = validatedBookings.find((booking) => booking.lastViewed) || validatedBookings[0];
              ({ bookingId, passengerNumber } = currentBooking || {});
            } else if (userType === USER_TYPES.TA) {
              bookingId = currentBookingId;
            }
            if (bookingId) {
              const lockData = await verifyLockUnlockStatus({
                type: LOCK_TYPES.LOCK,
                bookingId,
                isOriginBookingId: true,
                passengerNumber,
              });
              if (!lockData) {
                fetchCommonContent().then(() => {
                  this.setState({
                    commonContentLoaded: true,
                  });
                });
                triggerPageView(pathname, '', {
                  booking_data_initialized: true,
                  event: 'booking_data_initialized',
                  event_name: 'booking_data_initialized',
                  booking_id: NO_BOOKING_ID,
                });
              } else {
                const { data: { booking: bookingDetails = {} } = {} } = lockData;
                let data = bookingDetails;
                const { bookingNumber } = bookingDetails;
                // Verify this is needed. With validate booking before lock, we should always be locking
                // correct booking, and not need this second call option
                if (lockData.isSuccessful && currentBookingId !== bookingNumber) {
                  data = await fetchBookings(currentBookingId);
                }
                let office;
                let closeToDepartureDate = false;
                if (data && data.office && data.guestDepartureDate) {
                  ({ office } = data);
                  const { guestDepartureDate } = data;
                  // eslint-disable-next-line max-len
                  const daysToGo = Math.ceil(moment.duration(moment(guestDepartureDate).diff(moment())).asDays());
                  closeToDepartureDate = daysToGo < DAYS_TO_GO.DEFAULT;
                }

                initializeBookingData(
                  { event: 'page_data_initialized', event_name: 'page_data_initialized', ...data },
                  pathname
                );

                // Check if auto accepting the PTC
                const ptcRes = await autoAcceptPtc(office, passengerNumber, ptc?.reference);
                const passengerId = passengerNumber || ptcRes?.passengerId;
                handleDeepLink();
                fetchCommonContent().then(() => {
                  this.setState({
                    commonContentLoaded: true,
                  });
                });
                if (data && data.bookingNumber) {
                  if (passengerId) {
                    fetchNotifications();
                  }
                  if (passengerId || userType === USER_TYPES.TA) {
                    fetchCart(closeToDepartureDate);
                  }
                }

                // Re-lock every 13 min
                this.timerID = setInterval(() => {
                  refreshLockStatus();
                  refreshIdTokenExpiration();
                }, MVJ_LOCK_TIMER);
              }
            } else {
              verifyLockUnlockStatus({
                type: LOCK_TYPES.LOCK,
                bookingId,
                isOriginBookingId: true,
                passengerNumber,
              });
              fetchCommonContent().then(() => {
                this.setState({
                  commonContentLoaded: true,
                });
              });
              triggerPageView(pathname, '', {
                booking_data_initialized: true,
                event: 'booking_data_initialized',
                event_name: 'booking_data_initialized',
                booking_id: NO_BOOKING_ID,
              });
            }
            setAnalyticsSessionId();
            this.passDataToLPChatAgent();
            this.setState({ authState: AUTH_STATES.SIGNED_IN });
          }
        );
      } else if (userData?.email) {
        fetchCommonContent().then(() => {
          this.setState({
            commonContentLoaded: true,
            authState: AUTH_STATES.SIGNED_IN,
          });
        });
      } else {
        fetchCommonContent().then(() => {
          clearInterval(this.timerID);
        });
      }
    }
  }

  setHeader = (isErrorPage, isHeaderContentAvailable) => {
    if (!isErrorPage) {
      return <Header />;
    }
    return isHeaderContentAvailable && <Header />;
  };

  setFooter = (isErrorPage, isFooterContentAvailable, showAirNav) => {
    if (isFooterContentAvailable && showAirNav) {
      return (
        <>
          <AirNav />
          <Footer />
        </>
      );
    }
    if (!isErrorPage) {
      return <Footer />;
    }
    return isFooterContentAvailable && <Footer />;
  };

  isLocationChanged = (prevProps) => {
    const {
      location: { pathname },
    } = this.props;
    return prevProps.location.pathname !== pathname;
  };

  async syncAuthState(newValue, isTokenAuthenticating) {
    const {
      bookingData: { bookingNumber },
      userBookingData,
      verifyLockUnlockStatus,
      authLoading,
      authData,
    } = this.props;

    const userBooking =
      userBookingData.find((booking) => (bookingNumber ? booking.bookingId === bookingNumber : booking.lastViewed)) ||
      {};
    const { passengerNumber } = userBooking;
    const sub = get(authData, 'attributes.sub', null);
    if (sub && sub !== newValue && !authLoading) {
      await verifyLockUnlockStatus({
        type: LOCK_TYPES.UNLOCK,
        bookingId: bookingNumber,
        isOriginBookingId: true,
        passengerNumber,
        signOutFlag: true,
      }).then(() => {
        window.location.reload(true);
      });
    } else if (sub !== newValue) {
      if (!authLoading && newValue && !isTokenAuthenticating) {
        window.location.reload(true);
      }
    }
  }

  alertIfCloseToSailing() {
    const { handleModalOpen } = this.props;
    handleModalOpen(MODALS.CLOSE_TO_SAILING);
  }

  navSignOut() {
    const { signOut } = this.props;
    return signOut('');
  }

  idleSignOut() {
    const { signOut } = this.props;
    // TODO: Get text from CMS
    return signOut('You have been logged out due to inactivity. Please log in again to continue.');
  }

  passDataToLPChatAgent() {
    const { bookingData, cartItems, pageName, userType } = this.props;
    const { bookingNumber, voyage, passengers } = bookingData;
    if (userType && userType !== USER_TYPES.TA) {
      const userBookingData = {
        bookingNumber,
        passengers:
          passengers &&
          passengers.map(
            (pax) =>
              pax && {
                cchid: pax.cchid,
                firstName: pax.firstName,
                lastName: pax.lastName,
                airPassengerId: pax.airPassengerId,
              }
          ),
        voyage: voyage && {
          id: voyage.id,
          type: voyage.type,
          direction: voyage.direction,
          numberOfDays: voyage.numberOfDays,
        },
        paymentCart: {
          items: cartItems,
        },
        guestStep: pageName || '',
      };

      liveChatUpdate(userBookingData);
    }
  }

  // eslint-disable-next-line consistent-return
  async authenticateB2c() {
    const { handleReceiveAuthData, location, fetchUserId, handleImpersonationData, impersonationData } = this.props;
    const { isImpersonationRedirect } = this.state;

    const { id, email, pax } = getQueryParams(location?.search);
    debugLog(`${id}, ${email}`);

    const acquireTokens = async () => {
      const loginRequest = {
        authority: MSAL_CONFIG.auth.authorities.signUpSignIn,
        scopes: MSAL_CONFIG.auth.scopes,
      };
      if (impersonationData) {
        loginRequest.authority = MSAL_CONFIG.auth.authorities.impersonation;
      }
      return msalInstance
        .acquireTokenSilent(loginRequest)
        .then(async (res) => {
          debugLog({ res });
          await handleReceiveAuthData(res);
          msalInstance.setActiveAccount(res?.account);
          this.setState({
            isAuthenticating: false,
          });
          return res;
        })
        .catch((error) => {
          // Handle error, maybe trigger a new login
          if (error.errorCode === 'invalid_grant') {
            const signInRequest = {
              authority: MSAL_CONFIG.auth.authorities.signUpSignIn,
              scopes: MSAL_CONFIG.auth.scopes,
            };
            msalInstance
              .ssoSilent(signInRequest)
              // eslint-disable-next-line no-unused-vars
              .then(async (ssoResponse) => {
                // Add handling for SSO response
                await handleReceiveAuthData(ssoResponse);
                this.setState({
                  isAuthenticating: false,
                });
              })
              // eslint-disable-next-line no-unused-vars
              .catch((ssoError) => {
                // Add error handling for SSO logging attempt failure
              });
          }
        });
    };

    if (isImpersonationRedirect) {
      await acquireTokens();
    } else if (id && email && pax) {
      handleImpersonationData(id, pax);
      // authenticate via impersonation flow
      const { userId } = await fetchUserId(email, id);
      debugLog(userId);
      if (impersonationData) {
        await msalInstance.loginRedirect({
          authority: MSAL_CONFIG.auth.authorities.impersonation,
          scopes: MSAL_CONFIG.auth.scopes,
          extraQueryParameters: {
            targetObjectId: userId,
          },
        });
      }
      return null;
    } else {
      await acquireTokens();
    }

    if (!msalInstance.getActiveAccount()) {
      const signInRequest = {
        authority: MSAL_CONFIG.auth.authorities.signUpSignIn,
        scopes: MSAL_CONFIG.auth.scopes,
      };

      debugLog('MSAL HANDLE REDIRECT PROMISE');
      // Handle redirect callback when the page is loaded
      msalInstance
        .handleRedirectPromise()
        .then(async (response) => {
          if (response) {
            await handleReceiveAuthData(response);
          } else {
            const allowedSignedOutRoutes = [
              APP_PATHS.LOGIN_FAQS,
              APP_PATHS.SYSTEM_MAINTENANCE,
              APP_PATHS.MAINTENANCE,
              APP_PATHS.OOPS_PAGE,
            ];
            if (allowedSignedOutRoutes.includes(location.pathname)) {
              throw new Error('test error'); // TODO: MR-14751 - Figure out if we need anything here
            }
            msalInstance.loginRedirect(signInRequest);
          }
          return response;
        })
        .catch((error) => {
          // When the user switches to Password flow.
          if (error.errorCode === 'invalid_grant') {
            msalInstance
              .ssoSilent(signInRequest)
              // eslint-disable-next-line no-unused-vars
              .then(async (ssoResponse) => {
                // Add handling for SSO response
                await handleReceiveAuthData(ssoResponse);
              })
              // eslint-disable-next-line no-unused-vars
              .catch((ssoError) => {
                // Add error handling for SSO logging attempt failure
                debugLog(`MSAL: ${JSON.stringify({ ssoError })}`);
              });
          }
          // Any other error such as cancellation.
          // TODO: Add error handling for when user cancels out of login flow
          return error;
        });
    }
  }

  render() {
    const {
      airData,
      authLoading,
      bookingData,
      bookingLoading,
      changeBooking,
      cruiseInfoError,
      heroImages,
      heroTitle,
      isErrorPage,
      isFooterContentAvailable,
      isHeaderContentAvailable,
      isViewOnly,
      loadedStatus,
      location,
      lockout,
      lockStatus,
      splashPageLink,
      unAuthorizedLoading,
      userBookingData,
      userType,
      fatalAuthError,
      handleErrorRedirect,
      defaultHeroImages,
    } = this.props;
    const { isInitialLoadComplete, commonContentLoaded, redirectToError } = this.state;

    const TIMEOUT_MINUTES = 50;
    const { bookingNumber } = bookingData;
    const userBooking =
      userBookingData.find((booking) => (bookingNumber ? booking.bookingId === bookingNumber : booking.lastViewed)) ||
      {};
    const userBookingLoaded = userBooking.ptcAccepted;
    const userBookingDisabled = userBooking?.notReadyForMVJ;

    // eslint-disable-next-line max-len
    const showAirNav = [AIR_PATHS.SEARCH, AIR_PATHS.SEATS, AIR_PATHS.REVIEW].some((path) =>
      location.pathname.includes(path)
    );
    const header = this.setHeader(isErrorPage, isHeaderContentAvailable);
    const footer = this.setFooter(isErrorPage, isFooterContentAvailable, showAirNav);
    const isMenuPage = location.pathname.includes(APP_PATHS.MENU) && userBookingLoaded && loadedStatus;

    if (userBookingDisabled && location.pathname !== APP_PATHS.OOPS_PAGE) {
      handleErrorRedirect(OOPS_PAGE_ERRORS.ALREADY_BOOKED);
    }

    const LOCKOUT_VALID_PATHS = [
      APP_PATHS.ACCOUNT,
      APP_PATHS.ACCOUNT_PROFILE,
      APP_PATHS.SIGN_OUT,
      APP_PATHS.CURRENTLY_UNAVAILABLE,
      APP_PATHS.HELP,
      APP_PATHS.HELP_AIR,
      APP_PATHS.HELP_FOREIGN_CURRENCY,
      APP_PATHS.HELP_LIFE_ON_BOARD,
      APP_PATHS.HELP_MY_SHIP,
      APP_PATHS.HELP_TOP_10,
      APP_PATHS.HELP_TRIP_DOCUMENTS,
      APP_PATHS.OOPS_PAGE,
    ];

    const AIR_STATE_PATHS = [
      AIR_PATHS.CHECKOUT,
      AIR_PATHS.DONE,
      AIR_PATHS.REVIEW,
      AIR_PATHS.SEARCH,
      AIR_PATHS.SEATS,
      AIR_PATHS.SELECT_PASSENGERS,
    ];

    const REMOVED_URL_PATHS = [APP_PATHS.HEALTH_SURVEY_CHECK_IN, APP_PATHS.HEALTH_SURVEY_CHECK_IN_FORM];
    const VALID_ERROR_PATHS = [APP_PATHS.VOYAGE_NOT_AVAILABLE, APP_PATHS.MAINTENANCE, APP_PATHS.SYSTEM_MAINTENANCE];
    if (
      splashPageLink &&
      location.pathname !== splashPageLink &&
      !LOCKOUT_VALID_PATHS.includes(location.pathname) &&
      !changeBooking &&
      !bookingLoading &&
      isInitialLoadComplete &&
      commonContentLoaded
    ) {
      navigateTo(splashPageLink);
    }

    if (location.pathname === VOYAGE_STATUSES.NO_BOOKINGS && userBookingData?.length) {
      navigateTo(APP_PATHS.INDEX);
    }

    return (
      <>
        <AuthenticatedTemplate>
          {isMenuPage && (
            <Suspense fallback={null}>
              <Route exact path={`${APP_PATHS.MENU}/:restaurantId`} render={renderComponent(DiningMenu)} />
            </Suspense>
          )}
          {redirectToError && (
            <HeaderFooterWrapper header={header} footer={footer}>
              <Suspense fallback={null}>
                <IdleTimer
                  ref={(ref) => {
                    this.idleTimer = ref;
                  }}
                  onIdle={this.idleSignOut}
                  timeout={TIMEOUT_MINUTES * 60 * 1000} // ms
                  startOnMount
                  stopOnIdle
                />
                <LoadingPage
                  loaded={(commonContentLoaded && isInitialLoadComplete && !authLoading) || fatalAuthError}
                  variant="blue"
                />
                {userBookingLoaded && lockStatus ? (
                  <>
                    <Switch location={location}>
                      {!VALID_ERROR_PATHS.includes(location.pathname) && (
                        <Redirect to={APP_PATHS.VOYAGE_NOT_AVAILABLE} />
                      )}
                      <Route path={APP_PATHS.MAINTENANCE} render={renderComponent(Maintenance)} />
                      <Route path={APP_PATHS.SYSTEM_MAINTENANCE} render={renderComponent(SystemMaintenance)} />
                      <Route path={APP_PATHS.OOPS_PAGE} render={renderComponent(OopsPage)} />
                      <Route path={APP_PATHS.VOYAGE_NOT_AVAILABLE} render={renderComponent(voyageNotAvailable)} />
                    </Switch>
                  </>
                ) : (
                  lockout && (
                    <HeaderFooterWrapper header={header} footer={footer}>
                      <Switch location={location}>
                        {!LOCKOUT_VALID_PATHS.includes(location.pathname) && <Redirect to={APP_PATHS.OOPS_PAGE} />}
                        <Route path={APP_PATHS.OOPS_PAGE} render={renderComponent(OopsPage)} />
                        <Route
                          path={APP_PATHS.CURRENTLY_UNAVAILABLE}
                          render={renderComponent(Maintenance, { locked: true })}
                        />
                      </Switch>
                    </HeaderFooterWrapper>
                  )
                )}
              </Suspense>
            </HeaderFooterWrapper>
          )}
          {!isMenuPage && !redirectToError && (
            <AppInsightsErrorBoundary
              onError={() => {
                if (!['local', 'dev'].includes(ENVIRONMENT_CODE)) {
                  navigateTo(APP_PATHS.OOPS_PAGE);
                  window.location.reload();
                }
                return null;
              }}
              appInsights={reactPlugin}
            >
              <Suspense fallback={null}>
                <IdleTimer
                  ref={(ref) => {
                    this.idleTimer = ref;
                  }}
                  onIdle={this.idleSignOut}
                  timeout={TIMEOUT_MINUTES * 60 * 1000} // ms
                  startOnMount
                  stopOnIdle
                />
                <LoadingPage
                  loaded={
                    (isInitialLoadComplete && commonContentLoaded && !authLoading && !bookingLoading) || fatalAuthError
                  }
                  variant="blue"
                />
                {userBookingLoaded && lockStatus ? (
                  <HeaderFooterWrapper
                    header={header}
                    footer={footer}
                    heroImages={heroImages}
                    heroTitle={heroTitle}
                    cruiseInfoError={cruiseInfoError}
                  >
                    <Switch location={location}>
                      {splashPageLink && !LOCKOUT_VALID_PATHS.includes(location.pathname) && (
                        <Route path={splashPageLink} render={renderComponent(SplashPage)} />
                      )}
                      {lockout && !isViewOnly && !LOCKOUT_VALID_PATHS.includes(location.pathname) && (
                        <Redirect to={APP_PATHS.CURRENTLY_UNAVAILABLE} />
                      )}
                      {REMOVED_URL_PATHS.includes(location.pathname) && <Redirect to={APP_PATHS.INDEX} />}
                      {isViewOnly && <Redirect from={APP_PATHS.ACCOUNT} to={APP_PATHS.INDEX} />}
                      <Redirect from={APP_PATHS.LOGIN} to={APP_PATHS.INDEX} />
                      <Redirect from={REDIRECT_APP_PATHS.GIF} to={APP_PATHS.DOCUMENTS} />
                      <Redirect from={REDIRECT_APP_PATHS.PATH_GIF} to={APP_PATHS.DOCUMENTS} />
                      <Redirect from={REDIRECT_APP_PATHS.WELCOME} to={APP_PATHS.INDEX} />
                      <Route exact path={APP_PATHS.INDEX} render={renderComponent(Home)} />
                      <Route path={APP_PATHS.ACCOUNT} render={renderComponent(Account)} />
                      <Route path={APP_PATHS.BEFORE_YOU_GO} render={renderComponent(BeforeYouGo)} />
                      <Route
                        path={APP_PATHS.CURRENTLY_UNAVAILABLE}
                        render={renderComponent(Maintenance, { locked: true })}
                      />
                      <Route path={APP_PATHS.DOCUMENTS} render={renderComponent(Documents)} />
                      <Route path={APP_PATHS.EXTENSIONS} render={renderComponent(Extensions)} />
                      <Route path={APP_PATHS.HELP_CATEGORY} render={renderComponent(Help)} />
                      <Route path={APP_PATHS.HOME} render={renderComponent(Home)} />
                      <Route path={APP_PATHS.ITINERARY} render={renderComponent(Calendar)} />
                      <Route path={APP_PATHS.LOGIN_FAQS} render={renderComponent(LoginFaqs)} />
                      <Route path={APP_PATHS.NOTIFICATIONS} render={renderComponent(Notifications)} />
                      <Route path={APP_PATHS.ONBOARD_EXPERIENCE} render={renderComponent(Onboard)} />
                      <Route path={APP_PATHS.OOPS_PAGE} render={renderComponent(OopsPage)} />
                      <Route path={APP_PATHS.PAYMENTS} render={renderComponent(Payments)} />
                      <Route path={APP_PATHS.SCHEDULE_PAYMENT} render={renderComponent(SchedulePayment)} />
                      <Route path={APP_PATHS.SHORE_EXCURSIONS} render={renderComponent(Shorex)} />
                      <Route path={APP_PATHS.SUBMARINE_VIDEO} render={renderComponent(SubmarineVideo)} />
                      <Route path={APP_PATHS.SYSTEM_MAINTENANCE} render={renderComponent(SystemMaintenance)} />
                      <Route path={APP_PATHS.TRAVEL_BOOKING} render={renderComponent(TravelBooking)} />
                      <Route path={APP_PATHS.TRAVEL_REQUIREMENTS} render={renderComponent(TravelRequirements)} />
                      <Route path={APP_PATHS.VOYAGE_NOT_AVAILABLE} render={renderComponent(voyageNotAvailable)} />
                      <div className="air-container">
                        <Switch>
                          {AIR_STATE_PATHS.includes(location.pathname) && isEmpty(airData) && (
                            <Redirect to={AIR_PATHS.HOME} />
                          )}
                          {location.pathname.startsWith(APP_PATHS.LEGACY_ABE_AIR) && <Redirect to={AIR_PATHS.HOME} />}
                          <Route
                            exact
                            path={`${APP_PATHS.AIR}/select-passengers`}
                            render={renderComponent(SelectPassengers)}
                          />
                          <Route exact path={`${APP_PATHS.AIR}/search`} render={renderComponent(AirSearch)} />
                          <Route exact path={`${APP_PATHS.AIR}/seats`} render={renderComponent(Seats)} />
                          <Route exact path={`${APP_PATHS.AIR}/review`} render={renderComponent(AirReview)} />
                          <Route exact path={`${APP_PATHS.AIR}/checkout`} render={renderComponent(AirConfirm)} />
                          <Route
                            exact
                            path={`${APP_PATHS.AIR}/checkout-response`}
                            render={renderComponent(ConfirmResponse)}
                          />
                          <Route path={APP_PATHS.AIR} render={renderComponent(NewAir)} />
                          <Route path="*" render={renderComponent(OopsPage)} />
                        </Switch>
                      </div>
                    </Switch>
                    <CloseToSailingModal id={MODALS.CLOSE_TO_SAILING} />
                    <ViewOnlyModal id={MODALS.VIEW_ONLY} />
                  </HeaderFooterWrapper>
                ) : (
                  <>
                    {lockout && (
                      <>
                        <HeaderFooterWrapper
                          header={header}
                          footer={footer}
                          heroImages={heroImages.every((img) => img && img.src === NO_IMAGE) ? null : heroImages}
                          cruiseInfoError={cruiseInfoError}
                        >
                          <Switch location={location}>
                            {splashPageLink && !LOCKOUT_VALID_PATHS.includes(location.pathname) && (
                              <Route path={splashPageLink} render={renderComponent(SplashPage)} />
                            )}
                            {lockout && !LOCKOUT_VALID_PATHS.includes(location.pathname) && !splashPageLink && (
                              <Redirect to={APP_PATHS.OOPS_PAGE} />
                            )}
                            <Route path={APP_PATHS.OOPS_PAGE} render={renderComponent(OopsPage)} />
                            <Route
                              path={APP_PATHS.CURRENTLY_UNAVAILABLE}
                              render={renderComponent(Maintenance, { locked: true })}
                            />
                          </Switch>
                        </HeaderFooterWrapper>
                      </>
                    )}
                    {!lockout && (
                      <>
                        {!userBookingLoaded && !splashPageLink ? (
                          <Switch>
                            <Route path={APP_PATHS.OOPS_PAGE} render={renderComponent(OopsPage)} />
                            <Route path={APP_PATHS.SYSTEM_MAINTENANCE} render={renderComponent(SystemMaintenance)} />
                            <Route
                              path={APP_PATHS.CURRENTLY_UNAVAILABLE}
                              render={renderComponent(Maintenance, { locked: true })}
                            />
                            {!userBookingLoaded && userBookingData?.length && userType === USER_TYPES.CONSUMER && (
                              <Route path={APP_PATHS.INDEX} render={renderComponent(PassengerTicketContract)} />
                            )}
                          </Switch>
                        ) : (
                          <HeaderFooterWrapper
                            header={header}
                            footer={footer}
                            heroImages={heroImages.every((img) => img && img.src === NO_IMAGE) ? null : heroImages}
                            cruiseInfoError={cruiseInfoError}
                            splashPageLink={splashPageLink}
                            defaultHeroImages={defaultHeroImages}
                          >
                            <Switch>
                              {isViewOnly && !splashPageLink && <Redirect to={APP_PATHS.CURRENTLY_UNAVAILABLE} />}
                              <Route path={APP_PATHS.SYSTEM_MAINTENANCE} render={renderComponent(SystemMaintenance)} />
                              <Route
                                path={APP_PATHS.CURRENTLY_UNAVAILABLE}
                                render={renderComponent(Maintenance, { locked: true })}
                              />
                              <Route path={APP_PATHS.OOPS_PAGE} render={renderComponent(OopsPage)} />
                              {splashPageLink && !LOCKOUT_VALID_PATHS.includes(location.pathname) && (
                                <Route path={splashPageLink} render={renderComponent(SplashPage)} />
                              )}
                            </Switch>
                          </HeaderFooterWrapper>
                        )}
                      </>
                    )}
                  </>
                )}
              </Suspense>
            </AppInsightsErrorBoundary>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <>
            <Suspense fallback={null}>
              {unAuthorizedLoading && <LoadingPage variant="blue" />}
              <Switch location={location}>
                <Route path={APP_PATHS.LOGIN_FAQS} render={renderComponent(LoginFaqs)} />
                <Route path={APP_PATHS.MAINTENANCE} render={renderComponent(Maintenance)} />
                <Route path={APP_PATHS.OOPS_PAGE} render={renderComponent(OopsPage)} />
                <Route path={APP_PATHS.SYSTEM_MAINTENANCE} render={renderComponent(SystemMaintenance)} />
              </Switch>
            </Suspense>
          </>
        </UnauthenticatedTemplate>
      </>
    );
  }
}

App.propTypes = {
  activeRequestCount: PropTypes.number,
  splashPageLink: PropTypes.string,
  authData: PropTypes.shape({
    signInUserSession: PropTypes.shape({
      idToken: PropTypes.shape({
        payload: PropTypes.shape({
          email: PropTypes.string,
          'custom:booking': PropTypes.string,
          'custom:userType': PropTypes.string,
        }),
      }),
    }),
    username: PropTypes.string,
    bookingId: PropTypes.string,
  }),
  fetchNotifications: PropTypes.func.isRequired,
  fetchBookings: PropTypes.func.isRequired,
  verifyLockUnlockStatus: PropTypes.func,
  fetchCommonContent: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired,
  location: PropTypes.routerLocation.isRequired,
  loadedStatus: PropTypes.bool,
  signOut: PropTypes.func.isRequired,
  heroImages: PropTypes.heroImages,
  heroTitle: PropTypes.string.isRequired,
  bookingData: PropTypes.shape({
    bookingNumber: PropTypes.string,
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        cchid: PropTypes.string,
      })
    ),
    voyageId: PropTypes.string,
  }),
  userBookingData: PropTypes.arrayOf(
    PropTypes.shape({
      bookingId: PropTypes.string,
      bookingStatus: PropTypes.string,
      cruiseName: PropTypes.string,
      departureData: PropTypes.string,
      lastViewed: PropTypes.bool,
      voyageId: PropTypes.string,
    })
  ),
  changeBooking: PropTypes.bool,
  autoAcceptPtc: PropTypes.func.isRequired,
  handleQueryParams: PropTypes.func.isRequired,
  handleDeepLink: PropTypes.func.isRequired,
  lockStatus: PropTypes.string,
  isErrorPage: PropTypes.bool.isRequired,
  isHeaderContentAvailable: PropTypes.bool.isRequired,
  isFooterContentAvailable: PropTypes.bool.isRequired,
  authLoading: PropTypes.bool,
  refreshIdTokenExpiration: PropTypes.func.isRequired,
  lockout: PropTypes.bool,
  handleModalOpen: PropTypes.func.isRequired,
  alertUserCloseToDeparture: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  airData: PropTypes.shape({}),
  clearGIFNextLocation: PropTypes.func.isRequired,
  clearPaymentNextLocation: PropTypes.func.isRequired,
  cruiseInfoError: PropTypes.bool,
  pageName: PropTypes.string,
  unAuthorizedLoading: PropTypes.bool,
  setAnalyticsSessionId: PropTypes.func.isRequired,
};

App.defaultProps = {
  activeRequestCount: 0,
  splashPageLink: null,
  authData: {
    signInUserSession: {
      idToken: {
        payload: {
          email: '',
        },
      },
    },
  },
  verifyLockUnlockStatus: null,
  heroImages: [],
  loadedStatus: false,
  bookingData: {},
  userBookingData: [],
  changeBooking: false,
  lockStatus: '',
  authLoading: false,
  lockout: false,
  airData: {},
  cruiseInfoError: false,
};

export default App;
